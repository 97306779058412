import React from 'react';
import './index.css';
import {FormattedMessage} from "react-intl";

const Video = ({url}) => (
    <iframe
        width="560"
        height="315"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    >
    </iframe>
);

export default () => (
    <div id="specialists" className="specialists">
        <div className="specialists__header">
            <FormattedMessage id="youtube.header"/>
        </div>
        <div className="specialists__videos">
            <Video url="https://www.youtube.com/embed/bXPb1ntr770"/>
            <Video url="https://www.youtube.com/embed/hco74Y75tm8"/>
        </div>
    </div>
);
