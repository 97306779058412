import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/ee/vitamiinid/c-vitamiiniga-tooted">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="https://www.benu.ee/search-results-ee?vars/search/citromax">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/catalogsearch/result/?q=CITROMAX">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/search?q=CITROMAX">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/catalogsearch/result/?q=CITROMAX">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
